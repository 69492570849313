<template>
  <div class="ui stackable grid row dimmed funding">
    <div class="ui funding sixteen wide column col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="ui container funding-container">
        <div class="ui secondary pointing menu tabs">
          <router-link
            class="item tab"
            :class="{ active: index === 0 || isCurrentRouteActive('funding-explore', 0) }"
            :to="{ name: 'funding-explore' }"
            @click.native="setActiveIndex(0)"
            >Explore</router-link
          >
          <router-link
            class="item tab"
            :class="{ active: index === 1 || isCurrentRouteActive('funding-applications', 1) }"
            :to="{ name: 'funding-applications' }"
            @click.native="setActiveIndex(1)"
            >Applications</router-link
          >
          <router-link
            class="item tab"
            :class="{ active: index === 2 || isCurrentRouteActive('funding-deals', 2) }"
            :to="{ name: 'funding-deals' }"
            @click.native="setActiveIndex(2)"
            >Deals</router-link
          >
        </div>
        <router-view />
        <AggregatorProducts />
      </div>
    </div>
    <modal name="currentlyBankModal" height="auto" :scrollable="true" :width="780">
      <div class="currently-bank-modal">
        <div class="ui header dark text text-center">To correctly match you against the available CBILS products, please proceed and tell us who you currently bank with.</div>
        <div class="text-center m-t-15">
          <button class="btn btn-primary btn-sm p-l-40 p-r-40" type="button" @click="currentlyBankModalBtnClickHandler">Proceed to Funding Requirement</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { sidebarGetters } from '../../store/modules/sidebar/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { getCompany } from '@/api/company'
import { mapGetters } from 'vuex'

export default {
  name: 'Funding',
  data() {
    return {
      index: 0,
      limit: 0
    }
  },
  computed: {
    ...mapGetters({
      isSideBarOpen: sidebarGetters.SIDEBAR_STATUS,
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    setActiveIndex(index) {
      this.index = index
    },
    isCurrentRouteActive(routeName, index) {
      if (this.$route.name === routeName) {
        this.index = index
      }
      return this.$route.name === routeName
    },
    showCurrentlyBankModal() {
      return getCompany(this.companyId)
        .then(res => {
          if (res.data.currentlyBank === null && this.$config.whitelabel.journey.classicJourneyEnabled) {
            this.$modal.show('currentlyBankModal')
          }
        })
        .catch(e => {})
    },
    currentlyBankModalBtnClickHandler() {
      this.$modal.hide('currentlyBankModal')
    }
  },
  mounted() {
    this.showCurrentlyBankModal()
  },
  unmounted() {
    this.limit = 61
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/_variables.scss';
.tabs {
  @media only screen and (max-width: $breakpoint-xs-max) {
    padding: 0 20px;
  }
}
.ui.funding {
  .ui.funding.sixteen.wide.column {
    padding: 3rem 2rem 1rem;
    width: 100%;
    .ui.secondary.pointing.menu {
      border-bottom: 1px solid var(--color-primary-100);
    }
    @media only screen and (max-width: $breakpoint-xs-max) {
      padding: 1rem 0rem;
    }
    .ui.container {
      @media only screen and (max-width: $breakpoint-xs) {
        margin: 0rem;
      }

      /deep/.funding-empty-container {
        height: 244px;
      }
    }
  }
}
</style>
